import { Component, OnInit } from '@angular/core';
import { Covenant } from 'src/app/domain/covenant';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { AddressService } from 'src/app/service/address/address.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/domain/address';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant',
  templateUrl: './covenant.component.html',
  styleUrls: ['./covenant.component.css'],
})
export class CovenantComponent implements OnInit {
  covenants: Array<Covenant> = [];
  states: Array<State> = [];
  cities: Array<City> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  covenant: Covenant = new Covenant();
  collaborator: string = '';
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: CovenantService,
    private serviceAddress: AddressService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.covenant.address = new Address();
      this.covenant.address.city = new City();
      this.covenant.address.city.state = new State();

      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
      this.covenant.name = params.name || '';
      this.covenant.cpfCnpj = params.cpfCnpj || '';
      this.covenant.active = params.active || null;
      this.covenant.address.city.id = params.city || null;
      this.covenant.address.city.state.id = params.state || '';
      this.collaborator = params.collaborator || '';
    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getStates();
    this.getCovenants();
  }

  getStates() {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {}
    );
  }

  getCovenants() {
    this.loading = true;
    this.service
      .getCovenantsBackoffice(this.covenant, this.pagination, this.collaborator)
      .subscribe(
        (data) => {
          this.covenants = data['results'];
          this.setPagination(data);

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.openAlertError();
        }
      );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      name: [this.covenant.name],
      cpfCnpj: [this.covenant.cpfCnpj],
      state: [this.covenant.address.city.state.id],
      city: [this.covenant.address.city.id],
      phone: [this.covenant.municipalRegistration],
      whatsapp: [this.covenant.municipalRegistration],
      active: [this.covenant.active],
      collaborator: [this.collaborator],
    });
  }

  onSearch() {
    this.covenant.name = this.searchForm.controls.name.value;
    this.covenant.cpfCnpj = this.searchForm.controls.cpfCnpj.value;
    this.covenant.address.city.state.id = this.searchForm.controls.state.value;
    this.covenant.address.city.id = this.searchForm.controls.city.value;
    this.covenant.phone = this.searchForm.controls.phone.value;
    this.covenant.whatsapp = this.searchForm.controls.whatsapp.value;
    this.collaborator = this.searchForm.controls.collaborator.value;

    switch (this.searchForm.controls.active.value) {
      case 'true':
        this.covenant.active = true;
        break;
      case 'false':
        this.covenant.active = false;
        break;
      default:
        this.covenant.active = null;
        break;
    }
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
        },
        (error) => {
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }
  onActive(covenant: Covenant) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Convênio? ${covenant.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(covenant.id).subscribe(
          (data) => {
            // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
            Swal.fire({
              title: 'Ativado',
              text: `Convênio ativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao ativar Convênio!', 'error');
          }
        );
      }
    });
  }
  onDeActive(covenant: Covenant) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Convênio? ${covenant.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(covenant.id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Desativado',
              text: `Convênio desativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao desativar Convênio!', 'error');
          }
        );
      }
    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/maintenance/covenant'], {
      queryParams: {
        name: this.covenant.name ?? '',
        cpfCnpj: this.covenant.cpfCnpj ?? '',
        state: this.covenant.address.city.state.id ?? '',
        city: this.covenant.address.city.id ?? '',
        active: this.covenant.active ?? '',
        collaborator: this.collaborator,
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getCovenants();
  }
}
