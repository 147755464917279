<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Solicitação de Corrida</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/process/trip-request/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ngx-loading [show]="loading"> </ngx-loading>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select
                  (change)="onSelectedResults($event)"
                  class="custom-select custom-select-sm"
                >
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header style="width: 100%">
                <div [formGroup]="searchForm">
                  <div class="row">
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">Início</label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="dateStart"
                      />
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">Fim</label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="dateEnd"
                      />
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">Status</label>
                      <select
                        class="custom-select custom-select"
                        style="font-size: 14px"
                        formControlName="status"
                      >
                        <option value="">Status</option>
                        <option value="waiting">Aguardando</option>
                        <option value="approved">Aprovada</option>
                        <option value="rejected">Rejeitada</option>
                        <option value="canceled">Cancelada</option>
                        <option value="paid">Pago</option>
                      </select>
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2"
                        >Pagamento</label
                      >
                      <select
                        formControlName="paymentMethod"
                        class="custom-select custom-select"
                        style="font-size: 14px"
                      >
                        <option value="">Método de Pagamento</option>
                        <option
                          *ngFor="let paymentMethod of paymentMethods"
                          value="{{ paymentMethod.id }}"
                        >
                          {{ paymentMethod.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2"
                        >Centro de Custo</label
                      >
                      <select
                        formControlName="costCenter"
                        class="custom-select custom-select"
                        style="font-size: 14px"
                      >
                        <option value="">Centro de Custo</option>
                        <option
                          *ngFor="let costCenter of costCenters"
                          value="{{ costCenter.id }}"
                        >
                          {{ costCenter.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">
                        Convênio
                      </label>
                      <ng-select
                        class="custom-select"
                        style="font-size: 1rem; font-weight: normal"
                        notFoundText="Nenhum item encontrado"
                        placeholder="Convênio"
                        formControlName="covenant"
                        [items]="covenants"
                        bindLabel="name"
                        bindValue="id"
                      >
                      </ng-select>
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2"
                        >Passageiro</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Passageiro"
                        formControlName="passenger"
                      />
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2"
                        >Veículo</label
                      >
                      <ng-select
                        class="custom-select"
                        style="font-size: 1rem; font-weight: normal"
                        notFoundText="Nenhum item encontrado"
                        placeholder="Veículo"
                        formControlName="vehicle"
                        [items]="cars"
                        bindLabel="modelAndName"
                        bindValue="id"
                      >
                      </ng-select>
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">
                        Motorista
                      </label>
                      <ng-select
                        class="custom-select"
                        style="font-size: 1rem; font-weight: normal"
                        notFoundText="Nenhum item encontrado"
                        placeholder="Motorista"
                        formControlName="driver"
                        [items]="drivers"
                        bindLabel="name"
                        bindValue="id"
                      >
                      </ng-select>
                    </div>
                    <div class="col trip-request__button__search--div">
                      <button
                        (click)="onSearch()"
                        class="btn btn-info mr-3"
                        title="Buscar"
                      >
                        <i
                          class="fa fa-search fa-lg trip-request__button__search"
                        ></i>
                      </button>
                      <button
                        (click)="exportTripRequests('pdf')"
                        class="btn btn-danger mr-3"
                        title="Exportar para PDF"
                      >
                        <i
                          class="fa fa-file-pdf-o fa-lg trip-request__button__search"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <button
                        (click)="exportTripRequests('excel')"
                        class="btn btn-success"
                        title="Exportar para Excel"
                      >
                        <i
                          class="fa fa-file-excel-o fa-lg trip-request__button__search"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th>Código</th> -->
                      <th>Data do Serviço</th>
                      <th>Hora Início</th>
                      <th>Passageiro/Cliente</th>
                      <th>Origem</th>
                      <th>Destino</th>
                      <th>Valor da Corrida</th>
                      <th>Solicitação</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tripRequest of tripRequests">
                      <td *ngIf="false">
                        {{ tripRequest.id }}
                      </td>
                      <td *ngIf="tripRequest.shedule">
                        {{
                          moment(tripRequest.shedule.startDate).format(
                            "DD/MM/YYYY"
                          )
                        }}
                      </td>
                      <td *ngIf="!tripRequest.shedule">
                        {{ moment(tripRequest.createdAt).format("DD/MM/YYYY") }}
                      </td>
                      <td>
                        {{ tripRequest.startHour }}
                      </td>
                      <td
                        [innerHtml]="getPassengersName(tripRequest.passengers)"
                      ></td>
                      <td>
                        {{ tripRequest.origin }}
                      </td>
                      <td>
                        {{ tripRequest.destiny }}
                      </td>
                      <td>
                        <!-- <span *ngIf="tripRequest.client">{{ tripRequest.value |
                                                    currency:'BRL'}}</span>-->
                        <span>{{
                          tripRequest.runValue | currency: "BRL"
                        }}</span>
                      </td>
                      <td>
                        {{ tripRequest.location }}
                      </td>
                      <!-- <td>
                                                <span *ngIf="tripRequest.passengers.length > 1">Sim</span>
                                                <span *ngIf="tripRequest.passengers.length <= 1">Não</span>
                                            </td> -->
                      <td>
                        {{ tripRequest.status }}
                      </td>
                      <td>
                        <div class="">
                          <a
                            routerLink="/process/trip-request/view/{{
                              tripRequest.id
                            }}"
                            class="btn btn-primary"
                            title="Visualizar"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <a
                            *ngIf="
                              tripRequest.status == 'Aguardando' ||
                              tripRequest.status == 'Rejeitada'
                            "
                            routerLink="/process/trip-request/edit/{{
                              tripRequest.id
                            }}"
                            class="btn btn-warning"
                            title="Editar"
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                          <!-- <a
                            routerLink="/process/trip-request/edit/{{ tripRequest.id }}"
                            class="btn btn-warning"
                            title="Editar"
                          >
                            <i class="fa fa-pencil"></i>
                          </a> -->
                          <a
                            routerLink="/process/trip-request/trip-approval/{{
                              tripRequest.id
                            }}"
                            *ngIf="
                              (tripRequest.status == 'Aguardando' ||
                                tripRequest.status == 'Rejeitada') &&
                              isApprovalRequest
                            "
                            class="btn btn-success"
                            title="Aprovar"
                          >
                            <i class="fa fa-check-circle"></i>
                          </a>
                          <a
                            *ngIf="tripRequest.status !== 'Aguardando'"
                            routerLink="/process/runs/{{
                              tripRequest.id
                            }}/request"
                            class="btn btn-outline-info"
                            alt="Ver Corridas"
                            title="Ver Corridas"
                          >
                            <i class="fa fa-angle-double-right"></i>
                          </a>
                          <a
                            *ngIf="
                              (tripRequest.status == 'Aguardando' ||
                                tripRequest.status == 'Rejeitada') &&
                              tripRequest.covenant
                            "
                            routerLink="/process/trip-request/cancel/{{
                              tripRequest.id
                            }}"
                            class="btn btn-danger"
                            alt="Cancelar Corrida"
                            title="Cancelar"
                          >
                            <i class="fa fa-ban"></i>
                          </a>

                          <!-- <a class="btn btn-success ">
                                                        <i class="fa fa-random"></i>
                                                    </a> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
