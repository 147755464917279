import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViaCep } from 'src/app/domain/viacep';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { ClientService } from 'src/app/service/client/client.service';
import { AddressService } from 'src/app/service/address/address.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Client } from 'src/app/domain/client';
import { Address } from 'src/app/domain/address';
import { Validations } from '../../validations';

import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from '../../cropper/cropper-dialog';

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss'],
})
export class ClientCreateComponent implements OnInit {
  clientForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];
  paymentMethods;

  image: File;
  cropped?: string;

  constructor(
    private service: ClientService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private router: Router,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.getPaymentMethods();
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  createForm() {
    this.clientForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      cpfCnpj: ['' /*[Validators.required]*/],
      birthDate: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(60)],
      ],
      whatsapp: ['', [Validators.required, Validations.validwhatsapp]],
      paymentMethods: ['', Validators.required],
      phone: ['', [Validators.required, Validations.validPhone]],
      zipCode: ['', [Validators.required]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: [null, [Validators.required]],
      imageUrl: [''],
    });
  }
  getPaymentMethods() {
    this.loading = true;
    this.service.getPaymentMethods().subscribe(
      (data) => {
        this.paymentMethods = data;
        console.log('payment', this.paymentMethods, data);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Ops...',
          'Ocorreu um erro ao carregar as formas de pagamento!',
          'error'
        );
      }
    );
  }

  onRemoveImage(event) {
    this.cropped = '';
    this.image = null;
    this.clientForm.controls.imageUrl.reset('');
  }

  onSelectImage(file) {
    if (file.size > 10000000) {
      this.image = null;
      Swal.fire('Ops...', 'A imagem não pode ser maior que 10MB!', 'error');
      return false;
    } else {
      this.image = file;
      this.onFileReader();
      return true;
    }
  }

  onFileReader() {
    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () =>
      this.clientForm.controls.imageUrl.setValue(reader.result);
  }

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            this.viacep = data;

            if (!this.viacep.erro) {
              this.clientForm.controls.neighborhood.setValue(
                this.viacep.bairro
              );
              this.clientForm.controls.publicPlace.setValue(
                this.viacep.logradouro
              );
              const state = this.states.find(
                (state) => state.initials === this.viacep.uf.toUpperCase()
              );

              this.clientForm.controls.state.setValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      this.viacep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );
                  if (city) {
                    this.clientForm.controls.city.setValue(city.id);
                  }
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
            } else {
              this.clientForm.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.clientForm.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.clientForm.controls.zipCode.setErrors({ invalid: 'CEP inválido!' });
    }
  }

  onSelectedState(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  onSubmit() {
    let client = new Client();
    client.address = new Address();
    client.address.city = new City();
    client.name = this.clientForm.controls.name.value;
    client.cpfCnpj = this.clientForm.controls.cpfCnpj.value;
    client.birthDate = this.clientForm.controls.birthDate.value;
    client.email = this.clientForm.controls.email.value;
    client.phone = this.clientForm.controls.phone.value;
    client.whatsapp = this.clientForm.controls.whatsapp.value;
    client.address.zipCode = this.clientForm.controls.zipCode.value.replace(
      /[^0-9,.]+/g,
      ''
    );
    client.address.neighborhood = this.clientForm.controls.neighborhood.value;
    client.address.publicPlace = this.clientForm.controls.publicPlace.value;
    client.address.city.id = Number(this.clientForm.controls.city.value);

    client.paymentMethods = [];

    if (this.clientForm.controls.paymentMethods.value) {
      this.clientForm.controls.paymentMethods.value.forEach((method) => {
        client.paymentMethods.push({ id: Number(method) });
      });
    }

    this.loading = true;
    this.service.save(client).subscribe(
      (data) => {
        if (this.image) {
          this.service
            .imageUpload(data.body['id'], this.image)
            .subscribe((data) => {
              this.loading = false;
              this.showSuccess();
            }),
            (error) => {
              this.loading = false;
              Swal.fire(
                'Erro ao salvar imagem',
                'Cliente salvo, mas não foi possível salvar a imagem!',
                'error'
              );
            };
        } else {
          this.loading = false;
          this.showSuccess();
        }
        this.createForm();
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao salvar',
          'Não foi possível salvar cliente!',
          'error'
        );
      }
    );
  }

  showSuccess() {
    Swal.fire('Salvo', 'Cliente salvo com sucesso!!', 'success').then(
      (result) => {
        if (result.value) {
          this.router.navigate(['maintenance/client']);
        }
      }
    );
  }

  resetForm() {
    this.clientForm.controls.name.setValue('');
    this.clientForm.controls.cpfCnpj.setValue('');
    this.clientForm.controls.birthDate.setValue('');
    this.clientForm.controls.email.setValue('');
    this.clientForm.controls.phone.setValue('');
    this.clientForm.controls.whatsapp.setValue('');
    this.clientForm.controls.zipCode.setValue('');
    this.clientForm.controls.neighborhood.setValue('');
    this.clientForm.controls.publicPlace.setValue('');
    this.clientForm.controls.city.setValue('');
    this.clientForm.controls.state.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      console.log(erro);

      switch (erro.fieldName) {
        case 'name':
          this.clientForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case 'cpfCnpj':
          this.clientForm.controls.cpfCnpj.setErrors({ invalid: erro.message });
          break;
        case 'birthDate':
          this.clientForm.controls.contact.setErrors({ invalid: erro.message });
          break;
        case 'email':
          this.clientForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case 'phone':
          this.clientForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case 'whatsapp':
          this.clientForm.controls.whatsapp.setErrors({
            invalid: erro.message,
          });
          break;
        case 'zipCode':
          this.clientForm.controls.zipCode.setErrors({ invalid: erro.message });
          break;
        case 'neighborhood':
          this.clientForm.controls.neighborhood.setErrors({
            invalid: erro.message,
          });
          break;
        case 'publicPlace':
          this.clientForm.controls.publicPlace.setErrors({
            invalid: erro.message,
          });
          break;
        case 'city':
          this.clientForm.controls.city.setErrors({ invalid: erro.message });
          break;
        case 'state':
          this.clientForm.controls.state.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  onChangeDropzone(files) {
    console.log(files);
  }

  openCropperDialog(event) {
    const imgFile = event.target.files['0'];
    let selImg = this.onSelectImage(imgFile);

    if (selImg) {
      this.cropped = null!;
      this._dialog
        .open<CropperDialog, Event>(CropperDialog, {
          data: event,
          width: 320,
          disableClose: true,
        })
        .afterClosed.subscribe((result?: ImgCropperEvent) => {
          if (result) {
            this.cropped = result.dataURL;
            this._cd.markForCheck();
          }
        });
    }
  }
}
