<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Corrida</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Data da Corrida</b>
                    </div>
                    <div class="col-md-12">
                      {{ run?.date | date : "dd/MM/yyyy" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Solicitação</b>
                    </div>
                    <div class="col-md-12">
                      <a
                        class="btn btn-outline-info"
                        routerLink="/process/trip-request/view/{{
                          run?.runRequest.id
                        }}"
                      >
                        #{{ run?.runRequest.id }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Código de Solicitação do Convênio</b>
                    </div>
                    <div class="col-md-12">#{{ run?.covenantCode }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Hora de Início</b>
                    </div>
                    <div class="col-md-12">
                      {{ run?.startHour }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Hora de Fim</b>
                    </div>
                    <div class="col-md-12">
                      {{ run?.endHour }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Status</b>
                    </div>
                    <div class="col-md-12">
                      <span
                        *ngIf="
                          run?.status != 'canceled' && run?.status != 'rejected'
                        "
                        class="label label-sucess label-mini"
                        >{{ run?.status }}</span
                      >
                      <span
                        *ngIf="
                          run?.status == 'canceled' || run?.status == 'rejected'
                        "
                        class="label label-danger label-mini"
                        >{{ run?.status }}</span
                      >
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>
              </div>
              <h3 class="mt-3">Passageiros</h3>
              <div
                *ngIf="run && run.runRequest.covenant"
                class="table-responsive mt-3"
              >
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Colaborador</th>
                      <th>Check-in</th>
                      <th>Check-out</th>
                      <th>Ponto de Destino</th>
                      <th>Principal</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    *ngFor="let passenger of run?.runPassengers; let i = index"
                  >
                    <tr>
                      <td>
                        {{ passenger?.collaborator?.name }}
                      </td>
                      <td>
                        {{
                          passenger?.checkInTime
                            ? passenger?.checkInTime
                            : "Não aplicado"
                        }}
                      </td>
                      <td>
                        {{
                          passenger?.checkOutTime
                            ? passenger?.checkOutTime
                            : "Não aplicado"
                        }}
                      </td>
                      <td>{{ passenger?.destinyPoint.name }}</td>
                      <td>
                        <span *ngIf="passenger?.isMain">Sim</span>
                        <span *ngIf="!passenger?.isMain">Não</span>
                      </td>
                      <td>
                        <a
                          class="btn btn-primary"
                          data-toggle="collapse"
                          href="#collapseOrigin{{ i }}"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseOrigin"
                        >
                          Endereço de Origem
                        </a>
                        <a
                          class="btn btn-primary"
                          data-toggle="collapse"
                          href="#collapseDestiny{{ i }}"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseDestiny"
                          *ngIf="passenger.destiny"
                        >
                          Endereço de Destino
                        </a>
                      </td>
                    </tr>
                    <tr class="collapse" id="collapseOrigin{{ i }}">
                      <td colspan="6" class="hiddenRow">
                        <div class="card card-body">
                          <div class="row">
                            <b>Endereço de Origem</b>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="row"><b>CEP</b></div>
                              <div class="row">
                                {{ passenger.origin.zipCode }}
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="row"><b>Logradouro</b></div>
                              <div class="row">
                                {{ passenger.origin.publicPlace }}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row"><b>Bairro</b></div>
                              <div class="row">
                                {{ passenger.origin.neighborhood }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Estado</b></div>
                              <div class="row">
                                {{ passenger.origin.city.state.name }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Cidade</b></div>
                              <div class="row">
                                {{ passenger.origin.city.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr
                      class="collapse"
                      id="collapseDestiny{{ i }}"
                      *ngIf="passenger.destiny"
                    >
                      <td colspan="6" class="hiddenRow">
                        <div class="card card-body">
                          <div class="row">
                            <b>Endereço de Destino</b>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="row"><b>CEP</b></div>
                              <div class="row">
                                {{ passenger.destiny.zipCode }}
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="row"><b>Logradouro</b></div>
                              <div class="row">
                                {{ passenger.destiny.publicPlace }}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row"><b>Bairro</b></div>
                              <div class="row">
                                {{ passenger.destiny.neighborhood }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Estado</b></div>
                              <div class="row">
                                {{ passenger.destiny.city.state.name }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Cidade</b></div>
                              <div class="row">
                                {{ passenger.destiny.city.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                *ngIf="run && run.runRequest.client"
                class="table-responsive mt-3"
              >
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Colaborador</th>
                      <th>Check-in</th>
                      <th>Check-out</th>
                      <th>Ponto de Destino</th>
                      <th>Principal</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    *ngFor="let passenger of run?.runPassengers; let i = index"
                  >
                    <tr>
                      <td>
                        {{ passenger?.client?.name }}
                      </td>
                      <!-- <td>aaa</td>
                                            <td>aaa</td>
                                            <td>aaaa</td> -->
                      <td>
                        {{
                          passenger?.checkInTime
                            ? passenger?.checkInTime
                            : "Não aplicado"
                        }}
                      </td>
                      <td>
                        {{
                          passenger?.checkOutTime
                            ? passenger?.checkOutTime
                            : "Não aplicado"
                        }}
                      </td>
                      <td>{{ passenger?.destinyPoint.name }}</td>
                      <!-- <td>
                                                {{ passenger?.client?.whatsapp | mask: '+00 00 0 0000-0000' }}
                                            </td>
                                            <td>
                                                {{ passenger?.client?.costCenter }}
                                            </td> -->
                      <td>
                        <span *ngIf="passenger?.isMain">Sim</span>
                        <span *ngIf="!passenger?.isMain">Não</span>
                      </td>
                      <td>
                        <a
                          class="btn btn-primary"
                          data-toggle="collapse"
                          href="#collapseOrigin{{ i }}"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseOrigin"
                        >
                          Endereço de Origem
                        </a>
                        <a
                          class="btn btn-primary"
                          data-toggle="collapse"
                          href="#collapseDestiny{{ i }}"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseDestiny"
                        >
                          Endereço de Destino
                        </a>
                      </td>
                    </tr>
                    <tr class="collapse" id="collapseOrigin{{ i }}">
                      <td colspan="6" class="hiddenRow">
                        <div class="card card-body">
                          <div class="row">
                            <b>Endereço de Origem</b>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="row"><b>CEP</b></div>
                              <div class="row">
                                {{ passenger.origin.zipCode }}
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="row"><b>Logradouro</b></div>
                              <div class="row">
                                {{ passenger.origin.publicPlace }}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row"><b>Bairro</b></div>
                              <div class="row">
                                {{ passenger.origin.neighborhood }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Estado</b></div>
                              <div class="row">
                                {{ passenger.origin.city.state.name }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Cidade</b></div>
                              <div class="row">
                                {{ passenger.origin.city.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="collapse" id="collapseDestiny{{ i }}">
                      <td colspan="6" class="hiddenRow">
                        <div class="card card-body">
                          <div class="row">
                            <b>Endereço de Destino</b>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="row"><b>CEP</b></div>
                              <div class="row">
                                {{ passenger.destiny.zipCode }}
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="row"><b>Logradouro</b></div>
                              <div class="row">
                                {{ passenger.destiny.publicPlace }}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row"><b>Bairro</b></div>
                              <div class="row">
                                {{ passenger.destiny.neighborhood }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Estado</b></div>
                              <div class="row">
                                {{ passenger.destiny.city.state.name }}
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="row"><b>Cidade</b></div>
                              <div class="row">
                                {{ passenger.destiny.city.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h3 class="mt-3 mb-2 border-bottom">Dados da corrida</h3>

              <div class="row mt-2 mb-2">
                <div class="col-sm-4">
                  <b>Veículo</b>
                  <p *ngIf="run?.vehicle">
                    {{ run.vehicle?.model.name }} -
                    {{ run.vehicle?.carPlate }}
                  </p>
                  <p *ngIf="!run?.vehicle">Não Selecionado</p>
                </div>
                <div class="col-sm-4">
                  <b>Motorista</b>
                  <p *ngIf="run?.driver">
                    {{ run.driver.name }}
                  </p>
                  <p *ngIf="!run?.driver">Não Selecionado</p>
                </div>
              </div>

              <div class="row mt-2 mb-2">
                <div class="col-md-12">
                  <b>Check-in Motorista</b>
                </div>
                <div class="col-md-12">
                  <span *ngIf="run?.realStartHour">
                    {{ run?.realStartHour | date : "dd/MM/yyyy HH:mm" }}
                  </span>
                  <span *ngIf="!run?.realStartHour"> Não aplicado </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-6 mb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Valor da Corrida</b>
                    </div>
                    <div *ngIf="run?.runValue" class="col-md-12">
                      {{ run.runValue | currency : "BRL" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Valor Hora Adicional</b>
                    </div>
                    <div *ngIf="run?.runAditionalValue" class="col-md-12">
                      {{ run?.runAditionalValue | currency : "BRL" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Multa</b>
                    </div>
                    <div *ngIf="run?.runPenality" class="col-md-12">
                      {{ run.runPenality | currency : "BRL" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Outros Valores</b>
                    </div>
                    <div *ngIf="run?.runOtherValue" class="col-md-12">
                      {{ run?.runOtherValue | currency : "BRL" }}
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-6 mb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Descontos</b>
                    </div>
                    <div *ngIf="run?.runDiscount" class="col-md-12">
                      {{ run?.runDiscount | currency : "BRL" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Valor Final</b>
                    </div>
                    <div *ngIf="run?.runEndValue" class="col-md-12">
                      {{ run.runEndValue | currency : "BRL" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b>Observação</b>
                  <p *ngIf="run?.observation" style="white-space: pre-line">
                    {{ run.observation }}
                  </p>
                  <p *ngIf="!run?.observation">Nenhuma observação adicionada</p>
                </div>
              </div>

              <div class="card-footer mt-5">
                <div class="row">
                  <!-- <div class="col-md-4">
                                    <a href="/run/edit/{{ run.id }}" class="btn btn-warning">Editar</a>
                                </div> -->
                  <div class="col-md-4">
                    <a routerLink="/process/runs" class="btn btn-info"
                      >Voltar</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
