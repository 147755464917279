import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { element } from 'protractor';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  submenus = [
    { name: 'Cliente', link: '/client', menu: 1, active: false },
    { name: 'Convênio', link: '/covenant', menu: 1, active: false },
    { name: 'Motorista', link: '/driver', menu: 1, active: false },
    { name: 'Veículo', link: '/car', menu: 1, active: false },

    {
      name: 'Acerto com Motorista',
      link: '/pay-driver',
      menu: 2,
      active: false,
    },
    { name: 'Centro de Custo', link: '/cost-center', menu: 2, active: false },
    { name: 'Conta Bancária', link: '/account-bank', menu: 2, active: false },
    { name: 'Contas a Pagar', link: '/bills-to-pay', menu: 2, active: false },
    {
      name: 'Contas a Receber',
      link: '/bills-to-receive',
      menu: 2,
      active: false,
    },
    {
      name: 'Gerar Contas a Receber',
      link: '/generate-bills-to-receive',
      menu: 2,
      active: false,
    },
    { name: 'Livro Caixa', link: '/cash-flow', menu: 2, active: false },

    {
      name: 'Solicitação de Corrida',
      link: '/trip-request',
      menu: 3,
      active: false,
    },
    { name: 'Corrida', link: '/runs', menu: 3, active: false },

    { name: 'Alerta', link: '/alert', menu: 4, active: false },
    { name: 'Banco', link: '/bank', menu: 4, active: false },
    { name: 'Cartão de Crédito', link: '/credit-card', menu: 4, active: false },
    { name: 'Fornecedor', link: '/provider', menu: 4, active: false },
    { name: 'Peça', link: '/piece', menu: 4, active: false },
    {
      name: 'Posto de Combustível',
      link: '/gas-station',
      menu: 4,
      active: false,
    },
    { name: 'Seguradora', link: '/insurer', menu: 4, active: false },
    {
      name: 'Valor de Corrida',
      link: '/request-value',
      menu: 4,
      active: false,
    },
    {
      name: 'Parâmetro Financeiro',
      link: '/financial-parameter',
      menu: 4,
      active: false,
    },

    {
      name: 'Perfil de Acesso',
      link: '/access-profile',
      menu: 5,
      active: false,
    },
    { name: 'Usuário', link: '/user', menu: 5, active: false },

    { name: 'Abastecimento', link: '/car-refuel', menu: 6, active: false },

    {
      name: 'Corridas por hora',
      link: '/runs-per-hour',
      menu: 7,
      active: false,
    },

    {
      name: 'Corridas por motorista',
      link: '/amount-runs-per-driver',
      menu: 7,
      active: false,
    },

    { name: 'Fluxo de Caixa', link: '/cash-flow', menu: 6, active: false },
  ];

  user: string;
  permissions = [];
  menus = [
    {
      id: 0,
      name: 'Home',
      link: '/',
      icon: 'home',
      submenus: [],
    },
    {
      id: 1,
      name: 'Cadastros',
      link: '/maintenance',
      icon: 'email',
      submenus: [],
    },
    {
      id: 2,
      name: 'Financeiro',
      link: '/financial',
      icon: 'email',
      submenus: [],
    },
    {
      id: 3,
      name: 'Processos',
      link: '/process',
      icon: 'person',
      submenus: [],
    },
    {
      id: 4,
      name: 'Parâmetros',
      link: '/parameters',
      icon: 'build',
      submenus: [],
    },
    {
      id: 5,
      name: 'Segurança',
      link: '/security',
      icon: 'email',
      submenus: [],
    },
    {
      id: 6,
      name: 'Relatórios',
      link: '/reports',
      icon: 'email',
      submenus: [],
    },
    {
      id: 7,
      name: 'Dashboards',
      link: '/dashboards',
      icon: 'email',
      submenus: [],
    },
  ];

  constructor(private activatedRoute: ActivatedRoute, public router: Router) {
    this.user = localStorage.getItem('user');

    switch (this.user) {
      case 'Administrador':
        const { allowedPermissions } = this.getDecodedAccessToken();

        this.permissions = allowedPermissions;

        this.permissions?.forEach((permission) => {
          var submenu = this.submenus.find(
            (element) => element.name == permission.name
          );
          if (submenu) {
            var pos = this.menus
              .map(function (e) {
                return e.id;
              })
              .indexOf(submenu.menu);
            this.menus[pos].submenus.push(submenu);
          }
        });

        this.menus = this.menus.filter(
          (elem) => elem.link || elem.submenus.length !== 0
        );

        break;

      case 'Convênio':
        break;

      default:
        break;
    }

    const urlParts = [];
    this.activatedRoute.url.subscribe((urlPath) => {
      const url = urlPath[0]?.path;

      urlPath.forEach(({ path }) => {
        if (path && urlParts.length < 2) urlParts.push(path);
      });

      this.menus.map((menu) => {
        if (menu.link == '/' && !url) {
          menu['active'] = true;
        }
        if (menu.link === url || menu.link.substring(1) === url) {
          menu['active'] = true;
        } else {
          menu.submenus?.forEach((submenu) => {
            if (submenu.link.substring(1) === url) {
              menu['active'] = !!submenu;
            }

            submenu.active = urlParts
              .join('/')
              .includes(submenu.link.substring(1));
          });
        }
      });
    });
  }

  ngOnInit(): void {
    //var activated = mapped.find((item) => item === true);
  }

  toggleMenu(menu) {
    menu.active = !menu.active;
    this.menus.forEach((item) => {
      item['active'] = menu.id === item.id ? menu.active : false;
    });

    if (!menu.submenus?.length) {
      this.router.navigate([menu.link]);
    }
  }

  getDecodedAccessToken(): any {
    try {
      return jwt_decode(localStorage.getItem('token').substring(7));
    } catch (Error) {
      return null;
    }
  }
}
