import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { ValidationsTripRequest } from 'src/app/components/trip-request/validations/validations-trip-request';
import { Address } from 'src/app/domain/address';
import { Car } from 'src/app/domain/car';
import { City } from 'src/app/domain/city';
import { Collaborator } from 'src/app/domain/collaborator';
import { CostCenter } from 'src/app/domain/cost-center';
import { Covenant } from 'src/app/domain/covenant';
import { DestinyPoint } from 'src/app/domain/destiny-point';
import { Driver } from 'src/app/domain/driver';
import { PaymentMethod } from 'src/app/domain/paymentMethod';
import { RequestValue } from 'src/app/domain/request-value';
import { State } from 'src/app/domain/state';
import { AddressService } from 'src/app/service/address/address.service';
import { CarService } from 'src/app/service/car/car.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantTripRequestService } from 'src/app/service/covenant-trip-request/covenant-trip-request.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';

import { UtilsService } from 'src/app/service/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-trip-request',
  templateUrl: './covenant-trip-request.component.html',
  styleUrls: ['./covenant-trip-request.component.css'],
})
export class CovenantTripRequestComponent implements OnInit {
  tripForm: FormGroup;
  covenant: Covenant;
  searchClientForm: FormGroup;
  loading: boolean;
  states: Array<State> = [];
  paymentMethods: Array<PaymentMethod> = [];
  covenantAddress: Address;
  costCenters: Array<CostCenter> = [];
  cars: Array<Car> = [];
  drivers: Array<Driver> = [];
  covenantId: number;
  requestValues: Array<RequestValue> = [];
  destinyPoints: Array<DestinyPoint> = [];
  messageError: string = '';
  collaborators: Collaborator[];
  cities: Array<Array<City>> = [];
  selectDestinyCities: Array<Array<City>> = [];

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private serviceTrip: CovenantTripRequestService,
    private serviceAddress: AddressService,
    private serviceCovenant: CovenantService,
    private serviceCostCenter: CostCenterService,
    private carService: CarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.createClientForm();
    this.getStates();
    this.getAllCostCenters();
    this.getCovenant();
  }
  changePassenger(e) {
    this.resetDataForm();
    this.searchClientForm.controls.searchClient.setValue('');
  }
  getCovenant() {
    this.covenantId = JSON.parse(localStorage.getItem('convenant')).convenant;
    this.serviceCovenant.getCovenantPortal(this.covenantId).subscribe(
      (data) => {
        this.covenantAddress = data.address;
        this.covenantAddress = data.address;
        const { address, covenantRequestValues, covenantPaymentMethods } = data;

        this.paymentMethods = covenantPaymentMethods.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.requestValues = covenantRequestValues.map(
          ({ requestValue }) => requestValue
        );

        this.requestValues = this.requestValues.sort((a, b) =>
          a.destiny.localeCompare(b.destiny)
        );

        this.covenantAddress = address;

        this.serviceCovenant
          .getActiveCollaborators(this.covenantId, '')
          .subscribe((data) => {
            this.collaborators = data['results'];
          });
      },
      (error) => {
        Swal.fire('Error', 'Erro ao buscar Informações de Convênio.', 'error');
      }
    );
  }
  resetDataForm() {
    this.tripForm.controls.covenantCode.setValue('');
    this.tripForm.controls.covenantId.setValue('');
    this.tripForm.controls.clientId.setValue('');
    this.tripForm.controls.name.setValue('');
    this.tripForm.controls.whatsapp.setValue('');
    this.tripForm.controls.phone.setValue('');
    this.tripForm.controls.starttime.setValue('');
    this.tripForm.controls.endtime.setValue('');
    this.tripForm.controls.costcenter.setValue('');
    this.tripForm.controls.collaborator.setValue('');
    this.tripForm.controls.otherAddress.setValue('');
    this.tripForm.controls.car.setValue('');
    this.tripForm.controls.cost.setValue('');
    this.tripForm.controls.valueSolicitation.setValue('');
    this.tripForm.controls.observation.setValue('');
    this.tripForm.controls.recursive.setValue(false);
    this.tripForm.controls.allDays.setValue(false);
    this.tripForm.controls.sunday.setValue(false);
    this.tripForm.controls.monday.setValue(false);
    this.tripForm.controls.tuesday.setValue(false);
    this.tripForm.controls.wednesday.setValue(false);
    this.tripForm.controls.thursday.setValue(false);
    this.tripForm.controls.friday.setValue(false);
    this.tripForm.controls.saturday.setValue(false);
    this.tripForm.controls.passengers.setValue([]);
    this.tripForm.controls.paymentMethod.setValue('');
  }
  getAllCostCenters() {
    this.serviceCostCenter.getAllCostCenters().subscribe((data) => {
      this.costCenters = data;
      this.costCenters = this.costCenters.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
  }

  createForm() {
    this.tripForm = this.fb.group({
      covenantCode: [''],
      startDate: [this.getAtualDate(), Validators.required],
      endDate: ['', [ValidationsTripRequest.validaEndDate]],
      starttime: ['', [Validators.required]],
      endtime: ['', [Validators.required]],
      costcenter: ['', []],
      collaborator: [''],
      otherAddress: [''],
      otherDestinyAddress: [true],
      valueSolicitation: ['', [Validators.required]],
      observation: [''],
      recursive: [false],
      scheduled: [false],
      allDays: [false],
      sunday: [false],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      passengers: this.fb.array([]),
      paymentMethod: ['', [Validators.required]],
    });
  }

  onAddCollaborator() {
    const collaborators = this.collaborators.filter(
      (elem) => elem['id'] == this.tripForm.controls.collaborator.value
    );
    const collaboratorsAdd = this.passengers.value.filter(
      (elem) => elem['id'] == this.tripForm.controls.collaborator.value
    );
    if (collaboratorsAdd.length == 0) {
      this.passengers.push(this.newCollaborator(collaborators[0]));
      this.passengers['controls'][this.passengers.length - 1][
        'controls'
      ].address.disable();
      this.passengers['controls'][this.passengers.length - 1][
        'controls'
      ].clientAddress.disable();
      this.tripForm.controls.collaborator.setValue('');
    } else {
      Swal.fire(
        'Atenção',
        'Colaborador já foi adicionado à Solicitação',
        'warning'
      );
    }
  }

  removeCollaborator(i: number) {
    this.passengers.removeAt(i);
  }

  newCollaborator(collaborator): FormGroup {
    this.serviceAddress
      .getCitiesByStateId(collaborator.address.city.state.id)
      .subscribe((data) => {
        this.cities.push(data);
        this.selectDestinyCities.push(data);
      });
    this.serviceAddress
      .getCitiesByStateId(collaborator.address.city.state.id)
      .subscribe((data) => this.cities.push(data));
    return this.fb.group({
      id: [collaborator.id],
      name: collaborator.name,
      phone: collaborator.phone,
      whatsapp: collaborator.whatsapp,
      costCenter: collaborator.costCenter,
      main: false,
      destinyPoint: ['', [Validators.required]],
      otherAddress: false,
      covenantAddress: false,
      clientAddress: true,
      otherDestinyAddress: true,
      covenantDestinyAddress: false,
      clientDestinyAddress: false,
      address: this.fb.group({
        id: [collaborator.address.id],
        zipcode: [collaborator.address.zipCode, [Validators.maxLength(9)]],
        publicPlace: [collaborator.address.publicPlace, []],
        neighborhood: [collaborator.address.neighborhood, []],
        state: [collaborator.address.city.state.id, []],
        city: [collaborator.address.city.id, []],
      }),
      destinyAddress: this.fb.group({
        id: [null],
        zipcode: ['', [Validators.required, Validators.maxLength(9)]],
        publicPlace: ['', [Validators.required]],
        neighborhood: ['', [Validators.required]],
        state: ['', [Validators.required]],
        city: ['', [Validators.required]],
      }),
    });
  }

  selectRequestValue() {
    const requestValue = this.requestValues.find(
      (elem) => this.tripForm.controls.valueSolicitation.value == elem.id
    );
    this.destinyPoints = requestValue ? requestValue.destinyPoints : [];
    this.destinyPoints = this.destinyPoints.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  get passengers() {
    return this.tripForm.get('passengers') as FormArray;
  }

  onChangeScheduded(e) {
    if (e.target.checked) {
      this.tripForm.controls.scheduled.setValue(true);
      this.tripForm.controls.startDate.setValue(this.getAtualDate());
    } else {
      this.tripForm.controls.scheduled.setValue(false);
    }
  }

  onClickCovenantAddress(e, i: number) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.disable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(true);
      this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.enable();
      this.setAddressPassengers(i, this.covenantAddress);
    }
  }

  onClickOtherAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.enable();
      this.passengers['controls'][i]['controls'].clientAddress.enable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      this.cities[i] = [];
      this.resetValueAddressPassenger(i);
    } else {
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      this.setAddressPassengers(i, collaborators[0]['address']);
      this.passengers['controls'][i]['controls'].address.disable();
    }
  }

  setAddressPassengers(i, { zipCode, publicPlace, neighborhood, city }) {
    this.passengers['controls'][i][
      'controls'
    ].address.controls.zipcode.setValue(zipCode);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.publicPlace.setValue(publicPlace);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.neighborhood.setValue(neighborhood);
    this.passengers['controls'][i]['controls'].address.controls.state.setValue(
      city.state.id
    );
    this.serviceAddress.getCitiesByStateId(city.state.id).subscribe(
      (data) => {
        this.cities[i] = data;
        this.passengers['controls'][i][
          'controls'
        ].address.controls.city.setValue(city.id);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
      }
    );
  }

  resetValueAddressPassenger(i) {
    this.passengers['controls'][i]['controls'].address.controls.id.setValue(
      null
    );
    this.passengers['controls'][i][
      'controls'
    ].address.controls.zipcode.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].address.controls.neighborhood.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].address.controls.publicPlace.setValue('');
    this.passengers['controls'][i]['controls'].address.controls.state.setValue(
      ''
    );
    this.passengers['controls'][i]['controls'].address.controls.city.setValue(
      ''
    );
  }

  resetValueDestinyAddressPassenger(i) {
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.id.setValue(null);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.zipcode.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.neighborhood.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.publicPlace.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.state.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.city.setValue('');
  }

  onCheckboxChangeRecursive(e) {
    if (e.target.checked) {
      this.tripForm.controls.recursive.setValue(true);
    } else {
      this.tripForm.controls.recursive.setValue(false);
    }
  }
  onCheckboxChangeAllDays(e) {
    if (e.target.checked) {
      this.tripForm.controls.sunday.setValue(true);
      this.tripForm.controls.monday.setValue(true);
      this.tripForm.controls.tuesday.setValue(true);
      this.tripForm.controls.wednesday.setValue(true);
      this.tripForm.controls.thursday.setValue(true);
      this.tripForm.controls.friday.setValue(true);
      this.tripForm.controls.saturday.setValue(true);

      this.tripForm.controls.sunday.disable();
      this.tripForm.controls.monday.disable();
      this.tripForm.controls.tuesday.disable();
      this.tripForm.controls.wednesday.disable();
      this.tripForm.controls.thursday.disable();
      this.tripForm.controls.friday.disable();
      this.tripForm.controls.saturday.disable();
    } else {
      this.tripForm.controls.sunday.setValue(false);
      this.tripForm.controls.monday.setValue(false);
      this.tripForm.controls.tuesday.setValue(false);
      this.tripForm.controls.wednesday.setValue(false);
      this.tripForm.controls.thursday.setValue(false);
      this.tripForm.controls.friday.setValue(false);
      this.tripForm.controls.saturday.setValue(false);

      this.tripForm.controls.sunday.enable();
      this.tripForm.controls.monday.enable();
      this.tripForm.controls.tuesday.enable();
      this.tripForm.controls.wednesday.enable();
      this.tripForm.controls.thursday.enable();
      this.tripForm.controls.friday.enable();
      this.tripForm.controls.saturday.enable();
    }
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.tripForm.controls.originAddress.get('zipcode').enable();
      this.tripForm.controls.originAddress.get('publicPlace').enable();
      this.tripForm.controls.originAddress.get('city').enable();
      this.tripForm.controls.originAddress.get('state').enable();
      this.tripForm.controls.originAddress.get('neighborhood').enable();
    } else {
      this.tripForm.controls.originAddress.get('zipcode').disable();
      this.tripForm.controls.originAddress.get('publicPlace').disable();
      this.tripForm.controls.originAddress.get('city').disable();
      this.tripForm.controls.originAddress.get('state').disable();
      this.tripForm.controls.originAddress.get('neighborhood').disable();
    }
  }

  getStates() {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  getSearchUser() {
    if (this.tripForm.controls.passenger.value == 'covenant') {
      this.getCovenantSearch();
    }
  }

  getCovenantSearch() {
    this.serviceCovenant
      .getCovenantSearch(this.searchClientForm.controls.searchClient.value)
      .subscribe((data) => {
        const value = data['results'][0];

        if (data['results'].length > 0 && data['results'][0] != null) {
          const { address } = value;
          this.covenantAddress = new Address();
          this.covenantAddress.zipCode = address.zipCode;
          this.covenantAddress.neighborhood = address.neighborhood;
          this.covenantAddress.publicPlace = address.publicPlace;
          this.covenantAddress.city = address.city;

          this.covenantId = value.id;
          this.tripForm.controls.covenantId.setValue(value.id);
          this.tripForm.controls.name.setValue(value.name);
          this.tripForm.controls.phone.setValue(value.phone);
          this.tripForm.controls.whatsapp.setValue(value.whatsapp);
          this.tripForm.controls.originAddress
            .get('zipcode')
            .setValue(value.address.zipCode);
          this.tripForm.controls.originAddress
            .get('publicPlace')
            .setValue(value.address.publicPlace);
          this.tripForm.controls.originAddress
            .get('state')
            .setValue(value.address.city.state.id);
          this.tripForm.controls.originAddress
            .get('neighborhood')
            .setValue(value.address.neighborhood);
          this.tripForm.controls.originAddress
            .get('city')
            .setValue(value.address.city.id);

          this.paymentMethods = value.covenantPaymentMethods;

          const { covenantRequestValues } = value;
          covenantRequestValues.forEach((element) => {
            this.requestValues.push(element.requestValue);
          });

          this.serviceCovenant
            .getCollaborators(this.covenantId, '')
            .subscribe((data) => {
              this.collaborators = data['results'];
            });
        } else {
          this.resetDataForm();
          this.searchClientForm.controls.searchClient.setErrors({
            invalid: 'Convênio não encontrado!',
          });
        }
      });
  }

  createClientForm() {
    this.searchClientForm = this.fb.group({
      searchClient: ['', [Validators.required]],
    });
  }

  async onBlurCepOriginCollaborator(event, i) {
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          const { controls } =
            this.passengers['controls'][i]['controls'].address;

          controls.neighborhood.setValue(viacep.bairro);
          controls.publicPlace.setValue(viacep.logradouro);

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );
          controls.state.setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.cities[i] = data;
              const city = this.cities[i].find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                controls.city.setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );

          controls.city.setValue('');

          this.loading = false;
        } else {
          this.loading = false;
          this.tripForm.controls.passengers
            .get(i)
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.passengers
        .get(i)
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  async onBlurCepDestinyCollaborator(event, i) {
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          const { controls } =
            this.passengers['controls'][i]['controls'].destinyAddress;

          controls.neighborhood.setValue(viacep.bairro);
          controls.publicPlace.setValue(viacep.logradouro);

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );
          controls.state.setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.selectDestinyCities[i] = data;
              const city = this.selectDestinyCities[i].find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                controls.city.setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );

          controls.city.setValue('');

          this.loading = false;
        } else {
          this.loading = false;
          this.tripForm.controls.passengers
            .get(i)
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.passengers
        .get(i)
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  async buscaCep(cep: string) {
    return await this.utilsService.getCep(cep);
  }

  onSelectedStateCollaborator(event, id) {
    this.serviceAddress
      .getCitiesByStateId(event.target.value)
      .subscribe((data) => {
        this.cities[id] = data;
      });
  }

  onClickClientAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.disable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );

      this.setAddressPassengers(i, collaborators[0]['address']);
    }
  }

  onClickCovenantDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(true);
      this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.enable();
      // const collaborators = this.collaborators.filter(elem => elem['id'] == this.passengers['controls'][i]['controls'].id.value);
      this.setDestinyAddressPassengers(i, this.covenantAddress);
    } else {
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      this.setDestinyAddressPassengers(i, collaborators[0]['address']);
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
    }
  }

  onClickClientDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(false);
      this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setDestinyAddressPassengers(i, collaborators[0]['address']);
    }
  }

  onClickOtherDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.enable();
      this.passengers['controls'][i]['controls'].clientDestinyAddress.enable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        false
      );
      this.selectDestinyCities[i] = [];
      this.resetValueDestinyAddressPassenger(i);
    } else {
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      this.setDestinyAddressPassengers(i, collaborators[0]['address']);
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
    }
  }

  setDestinyAddressPassengers(
    i,
    { id, zipCode, publicPlace, neighborhood, city }
  ) {
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.id.setValue(id);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.zipcode.setValue(zipCode);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.publicPlace.setValue(publicPlace);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.neighborhood.setValue(neighborhood);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.state.setValue(city.state.id);
    this.serviceAddress.getCitiesByStateId(city.state.id).subscribe(
      (data) => {
        this.selectDestinyCities[i] = data;
        this.passengers['controls'][i][
          'controls'
        ].destinyAddress.controls.city.setValue(city.id);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
      }
    );
  }

  getAtualDate() {
    return moment().format('yyyy-MM-DD');
  }

  totalSolicitation() {
    const passengers = this.passengers.getRawValue();
    const requestValue = this.requestValues.find(
      (elem) => this.tripForm.controls.valueSolicitation.value == elem.id
    );
    const destinyPoints = passengers.reduce((acc, item) => {
      const destiny = this.destinyPoints.find(
        (elem) => item.destinyPoint == elem.id
      );
      if (destiny) {
        const index = acc.findIndex((a) => a?.id === destiny.id);

        if (index == -1) {
          acc.push({ id: destiny.id, value: destiny.value });
        }
      }
      return acc;
    }, []);

    const valuePoints = destinyPoints.reduce((acc, item) => {
      acc += Number(item.value);
      return acc;
    }, 0);

    const origins = passengers.reduce((acc, item) => {
      const index = acc.findIndex((a) => a === item.address.zipcode);
      if (index == -1) {
        acc.push(item.address.zipcode);
      }
      return acc;
    }, []);

    let totalSolicitationValue =
      (requestValue?.value ?? 0) +
      (valuePoints ?? 0) +
      ((origins?.length != 0 ? origins?.length - 1 : 0) *
        requestValue?.valueOriginPoint ?? 0);

    let { initialHour, endHour, percentagePenalty } = requestValue;

    if (initialHour && endHour && percentagePenalty) {
      const beginningTime = moment(initialHour, 'hh:mm');
      const endTime = moment(endHour, 'hh:mm');
      const beginingRunTime = moment(
        this.tripForm.controls.starttime.value,
        'hh:mm'
      );

      const hasPenality =
        (beginningTime.isBefore(endTime) &&
          beginingRunTime.isSameOrAfter(beginningTime) &&
          beginingRunTime.isSameOrBefore(endTime)) ||
        (beginningTime.isAfter(endTime) &&
          beginingRunTime.isSameOrAfter(beginningTime)) ||
        beginingRunTime.isSameOrBefore(endTime);

      if (hasPenality) {
        const overtimePenalityValue =
          totalSolicitationValue * percentagePenalty;
        totalSolicitationValue += overtimePenalityValue;
      }
    }

    return totalSolicitationValue;
  }

  onSubmit() {
    this.loading = true;
    const {
      covenantCode,
      passengers,
      costcenter,
      paymentMethod,
      scheduled,
      recursive,
      startDate,
      endDate,
      starttime,
      endtime,
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      observation,
      valueSolicitation,
    } = this.tripForm.controls;

    this.messageError = '';

    const passengersSelected = [];
    passengers['controls'].forEach((passenger) => {
      passengersSelected.push({
        id: passenger.controls.id.value,
        isMain: passenger.controls.main.value,
        destinyPoint: passenger.controls.destinyPoint.value,
        originAddress: {
          originAddressId: passenger.controls.address.controls.id.value ?? null,
          zipCode: passenger.controls.address.controls.zipcode.value,
          publicPlace: passenger.controls.address.controls.publicPlace.value,
          neighborhood: passenger.controls.address.controls.neighborhood.value,
          cityId: passenger.controls.address.controls.city.value,
        },
        destinyAddress: {
          destinyAddressId:
            passenger.controls.destinyAddress.controls.id.value ?? null,
          zipCode: passenger.controls.destinyAddress.controls.zipcode.value,
          publicPlace:
            passenger.controls.destinyAddress.controls.publicPlace.value,
          neighborhood:
            passenger.controls.destinyAddress.controls.neighborhood.value,
          cityId: passenger.controls.destinyAddress.controls.city.value,
        },
      });
    });

    var value = {};

    value = {
      covenantCode: covenantCode.value,
      costCenterCovenant: costcenter.value,
      paymentMethod: { id: Number(paymentMethod.value) },
      scheduled: scheduled.value,
      recursive: recursive.value,
      startDate: startDate.value == '' ? null : startDate.value,
      endDate: endDate.value == '' ? null : endDate.value,
      startHour: starttime.value == '' ? null : starttime.value,
      endHour: endtime.value == '' ? null : endtime.value,
      passengers: passengersSelected,
      sunday: sunday.value,
      monday: monday.value,
      tuesday: tuesday.value,
      wednesday: wednesday.value,
      thursday: thursday.value,
      friday: friday.value,
      saturday: saturday.value,
      requestValue: { id: Number(valueSolicitation.value) },
      runValue: this.totalSolicitation(),
      observation: observation.value,
      status: 'waiting',
    };
    this.serviceTrip.save(value).subscribe(
      (data) => {
        Swal.fire(
          'Salvo',
          'Solicitação de Corrida Salva com Sucesso!',
          'success'
        );
        this.loading = false;
        this.router.navigate(['convenio/covenant-trip-request-list']);
      },
      (error) => {
        Swal.fire('Erro', 'Erro ao Salvar Solicitação de Corrida!', 'error');
        this.setErrors(error.error.errors);
        this.loading = false;
      }
    );
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  onCollaboratorSearch($e) {
    if ($e.term) {
      this.serviceCovenant
        .getActiveCollaborators(this.covenantId, $e.term)
        .subscribe((response) => {
          response['results']?.forEach((item) => {
            const findCollaborator = this.collaborators?.find(
              (e) => e['id'] === item.id
            );
            if (!findCollaborator) {
              this.collaborators = [...this.collaborators, ...[item]].sort(
                (a, b) => a.name.localeCompare(b.name)
              );
            }
          });
        });
    }
  }

  getPassengerFieldValidity(field, i) {
    console.log({
      field: (<FormArray>this.tripForm.get('passengers')).controls[i].get(
        field
      ),
    });
    return !!(<FormArray>this.tripForm.get('passengers')).controls[i].get(field)
      .invalid;
  }

  getPassengerAddressValidity(address, field, i) {
    console.log({
      field: (<FormArray>this.tripForm.get('passengers')).controls[i].get(
        address
      ),
    });
    return !!(<FormArray>this.tripForm.get('passengers')).controls[i]
      .get(address)
      ?.get(field)?.invalid;
  }
}
