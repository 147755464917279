<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title"> Compartilhar Corrida </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-topline-red" *ngIf="messageError.length">
        <div class="alert alert-danger" role="alert">
          {{ messageError }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div *ngFor="let run of runs">
              <div class="card-head">
                <div class="row mb-2">
                  <header>
                    <label>Corrida #{{run.id}}</label>

                  </header>
                </div>

              </div>
              <div class="card-body ">
                <div class="row">
                  <div class="col-md-4">
                    <label class="mt-2" style="font-size: 16px;">
                      Data
                    </label>
                    <p>{{ run.date | date: 'dd/MM/yyyy' }}</p>
                  </div>
                  <div class="col-md-4">
                    <label class="mt-2" style="font-size: 16px;">
                      Hora Início
                    </label>
                    <p>{{ run.startHour }}</p>
                  </div>
                  <div class="col-md-4">
                    <label class="mt-2" style="font-size: 16px;">
                      Hora Fim
                    </label>
                    <p>{{ run.endHour }}</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <legend>Destino</legend>
                    <p>
                      {{ run.runRequest.requestValue.destiny }} - {{
                      run.runRequest.requestValue.value | currency:'BRL' }}
                    </p>
                  </div>
                </div>
                <fieldset>
                  <legend>Passageiros</legend>
                  <table class="table table-striped custom-table table-hover">
                    <thead>
                      <tr>
                        <th>Colaborador</th>
                        <th>Telefone</th>
                        <th>Whatsapp</th>
                        <th>Ponto de Destino</th>
                        <th>Principal</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let passenger of run.runRequest.passengers; let i=index">
                      <tr>
                        <td>
                          {{ passenger.collaborator.name }}
                        </td>
                        <td>
                          {{ passenger.collaborator.phone | mask: '(00) 0000-0000||(00) 0
                          0000-0000' }}
                        </td>
                        <td>
                          {{ passenger.collaborator.whatsapp | mask: '+00 00 0 0000-0000' }}
                        </td>
                        <td>
                          {{ passenger.destinyPoint.name }}
                        </td>
                        <td>
                          <span *ngIf="passenger.isMain">Sim</span>
                          <span *ngIf="!passenger.isMain">Não</span>
                        </td>
                        <td>
                          <a class="btn btn-primary" data-toggle="collapse" href="#collapseExample{{i}}" role="button"
                            aria-expanded="false" aria-controls="collapseExample">
                            Endereço
                          </a>
                        </td>
                      </tr>
                      <tr class="collapse" id="collapseExample{{i}}">
                        <td colspan="6" class="hiddenRow">
                          <div class="card card-body">
                            <div class="row">
                              <b>Endereço de Origem</b>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="row"><b>CEP</b></div>
                                <div class="row">{{ passenger.origin.zipCode }}
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class="row"><b>Logradouro</b></div>
                                <div class="row">{{ passenger.origin.neighborhood }}
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4">
                                <div class="row"><b>Bairro</b></div>
                                <div class="row">{{ passenger.origin.publicPlace}}
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="row"><b>Estado</b></div>
                                <div class="row">
                                  {{ passenger.origin.city.state.name}}</div>
                              </div>
                              <div class="col-md-4">
                                <div class="row"><b>Cidade</b></div>
                                <div class="row">{{ passenger.origin.city.name}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </div>
            </div>
            <div class="row ml-3 mr-3" [formGroup]="form">
              <div class="col-sm-6">
                <label>Centro de custo</label>
                <input type="text" class="form-control" placeholder="Centro de Custo" formControlName="costCenter" />
                <!-- <select class="custom-select custom-select" style="font-size: 14px;" formControlName="costCenter">
                  <option value="">Centro de Custo</option>
                  <option value="{{ costCenter.id }}" *ngFor="let costCenter of costCenters">
                    {{ costCenter.name }} </option>
                </select> -->
                <!-- <span *ngIf="form.controls.costCenter.errors && form.controls.costCenter.errors.required"
                  class="text-danger position-right">*Campo Obrigatório</span> -->
              </div>
              <div class="col-sm-6">
                <label>Método de Pagamento</label>
                <select class="custom-select custom-select" style="font-size: 14px;" formControlName="paymentMethod">
                  <option value="">Método de Pagamento</option>
                  <option value="{{ paymentMethod.id }}" *ngFor="let paymentMethod of paymentMethods">
                    {{ paymentMethod.name }} </option>
                </select>
                <span *ngIf="form.controls.paymentMethod.errors && form.controls.paymentMethod.errors.required"
                  class="text-danger position-right">*Campo Obrigatório</span>
              </div>
              <div class="row mt-3 ml-3 mb-3">
                <div class="col-md-5 mr-3 text-right">
                  <button (click)="onSubmit()" [disabled]="!form.valid" class="btn btn-success">Compartilhar</button>
                </div>
                <div class="col-md-5">
                  <a href="/convenio" class="btn btn-danger">Cancelar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
