<div class="sidebar-container">
  <div class="sidemenu-container navbar-collapse collapse">
    <div id="remove-scroll">
      <ul
        class="sidemenu page-header p-t-20"
        data-auto-scroll="true"
        data-slide-speed="200"
      >
        <li class="sidebar-toggler-wrapper hide">
          <div class="sidebar-toggler">
            <span></span>
          </div>
        </li>
        <!-- <li class="sidebar-user-panel">
               <div class="user-panel">
                   <div class="pull-left image">
                     <img src="../../assets/img/dp.jpg" class="img-circle user-img-circle" alt="User Image" />
                  </div>
                  <div class="pull-left info">
                     <p> Marria</p>
                     <a title="Inbox" href="email_inbox.html"><i class="material-icons">email</i></a>
                     <a title="Profile" href="user_profile.html"><i class="material-icons">person</i></a>
                     <a title="Logout" href="/logout"><i class="material-icons">power_settings_new</i></a>
                  </div>
               </div>
            </li> -->
        <li class="menu-heading"></li>
        <div *ngIf="user == 'Administrador'">
          <span *ngFor="let menu of menus">
            <li class="nav-item" [class.nav-open]="menu.active">
              <a
                (click)="toggleMenu(menu)"
                class="nav-link nav-toggle nav-color"
              >
                <!-- <i class="material-icons">{{ menu.icon }}</i> -->
                <span class="title nav-toggle">{{ menu.name }}</span>
                <span class="arrow" *ngIf="menu.submenus.length"></span>
              </a>

              <ul class="sub-menu" *ngIf="menu.submenus.length">
                <span *ngFor="let submenu of menu.submenus">
                  <li class="nav-item" [class.item-active]="submenu.active">
                    <a
                      [routerLink]="[menu.link + submenu.link]"
                      class="nav-link"
                    >
                      <span class="title nav-toggle">{{ submenu.name }}</span>
                    </a>
                  </li>
                </span>
              </ul>
            </li>
          </span>
        </div>
        <div *ngIf="user == 'Convênio'">
          <li class="nav-item">
            <a href="/convenio" class="nav-link nav-toggle">
              <!-- <i class="material-icons">home</i> -->
              <span class="title nav-toggle">Home</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="/convenio/my-account" class="nav-link nav-toggle">
              <!-- <i class="material-icons">home</i> -->
              <span class="title nav-toggle">Minha Conta</span>
            </a>
          </li>
          <!-- <li class="nav-item">
                  <a href="/convenio/covenant-trip-request" class="nav-link nav-toggle">
                     <span class="title nav-toggle">Criar Solicitação de Corrida</span>
                  </a>
               </li> -->
          <li class="nav-item">
            <a
              href="/convenio/covenant-trip-request-list"
              class="nav-link nav-toggle"
            >
              <!-- <i class="material-icons">home</i> -->
              <span class="title nav-toggle">Solicitação de Corrida</span>
            </a>
          </li>
          <!-- <li class="nav-item">
                  <a href="/convenio/covenant-share-trip" class="nav-link nav-toggle">
                     <span class="title nav-toggle">Compartilhar Solicitação de Corrida</span>
                  </a>
               </li> -->
          <!-- <li class="nav-item">
                  <a href="/convenio/covenant-trip-approval" class="nav-link nav-toggle">
                     <span class="title nav-toggle">Aprovar Solicitação de Corrida</span>
                  </a>
               </li> -->
          <li class="nav-item">
            <a href="/convenio/workers" class="nav-link nav-toggle">
              <!-- <i class="material-icons">home</i> -->
              <span class="title nav-toggle">Colaboradores</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>
